"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContextProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const wallet_adapter_react_1 = require("@solana/wallet-adapter-react");
const web3_js_1 = require("@solana/web3.js");
const react_1 = require("react");
const AutoConnectProvider_1 = require("./AutoConnectProvider");
const NetworkConfigurationProvider_1 = require("./NetworkConfigurationProvider");
// Built in wallets
const wallet_adapter_phantom_1 = require("@solana/wallet-adapter-phantom");
const wallet_adapter_solflare_1 = require("@solana/wallet-adapter-solflare");
const wallet_adapter_backpack_1 = require("@solana/wallet-adapter-backpack");
const wallet_adapter_glow_1 = require("@solana/wallet-adapter-glow");
const preferredExplorer_1 = require("./preferredExplorer");
const WalletContextProvider = ({ endpoint, children }) => {
    const { autoConnect } = (0, AutoConnectProvider_1.useAutoConnect)();
    const { networkConfiguration } = (0, NetworkConfigurationProvider_1.useNetworkConfiguration)();
    const network = networkConfiguration;
    const selectedEndpoint = (0, react_1.useMemo)(() => endpoint !== null && endpoint !== void 0 ? endpoint : (0, web3_js_1.clusterApiUrl)(network), [network]);
    const passThroughWallet = (() => {
        if (typeof window === 'undefined')
            return undefined;
        return window.Jupiter.passThroughWallet;
    })();
    const wallets = (0, react_1.useMemo)(() => {
        if (passThroughWallet) {
            return [];
        }
        return [
            new wallet_adapter_phantom_1.PhantomWalletAdapter(),
            new wallet_adapter_solflare_1.SolflareWalletAdapter(),
            new wallet_adapter_backpack_1.BackpackWalletAdapter(),
            new wallet_adapter_glow_1.GlowWalletAdapter(),
        ];
    }, [network]);
    const onError = (0, react_1.useCallback)((error) => {
        console.error({ type: 'error', message: error.message ? `${error.name}: ${error.message}` : error.name });
    }, []);
    return ((0, jsx_runtime_1.jsx)(wallet_adapter_react_1.ConnectionProvider, Object.assign({ endpoint: selectedEndpoint }, { children: (0, jsx_runtime_1.jsx)(wallet_adapter_react_1.WalletProvider, Object.assign({ wallets: wallets, onError: onError, autoConnect: autoConnect }, { children: children })) })));
};
const ContextProvider = ({ endpoint, defaultExplorer, children }) => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(NetworkConfigurationProvider_1.NetworkConfigurationProvider, { children: (0, jsx_runtime_1.jsx)(AutoConnectProvider_1.AutoConnectProvider, { children: (0, jsx_runtime_1.jsx)(WalletContextProvider, Object.assign({ endpoint: endpoint }, { children: (0, jsx_runtime_1.jsx)(preferredExplorer_1.PreferredExplorerProvider, Object.assign({ defaultExplorer: defaultExplorer }, { children: children })) })) }) }) }));
};
exports.ContextProvider = ContextProvider;
