"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INITIAL_FORM_CONFIG = exports.SOL_MINT_TOKEN_INFO = exports.WRAPPED_SOL_MINT = exports.JUPITER_DEFAULT_RPC = void 0;
const react_hook_1 = require("@jup-ag/react-hook");
const web3_js_1 = require("@solana/web3.js");
exports.JUPITER_DEFAULT_RPC = process.env.NEXT_PUBLIC_JUPITER_DEFAULT_RPC || 'https://neat-hidden-sanctuary.solana-mainnet.discover.quiknode.pro/2af5315d336f9ae920028bbb90a73b724dc1bbed';
exports.WRAPPED_SOL_MINT = new web3_js_1.PublicKey('So11111111111111111111111111111111111111112');
exports.SOL_MINT_TOKEN_INFO = {
    chainId: 101,
    address: 'So11111111111111111111111111111111111111112',
    symbol: 'SOL',
    name: 'Wrapped SOL',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png',
    tags: [],
    extensions: {
        website: 'https://solana.com/',
        serumV3Usdc: '9wFFyRfZBsuAha4YcuxcXLKwMxJR43S7fPfQLusDBzvT',
        serumV3Usdt: 'HWHvQhFmJB3NUcu1aihKmrKegfVxBEHzwVX6yZCKEsi1',
        coingeckoId: 'solana',
    },
};
exports.INITIAL_FORM_CONFIG = Object.freeze({
    useWalletPassthrough: false,
    strictTokenList: true,
    defaultExplorer: 'Solana Explorer',
    formProps: {
        fixedInputMint: false,
        fixedOutputMint: false,
        swapMode: react_hook_1.SwapMode.ExactIn,
        fixedAmount: false,
        initialAmount: '',
        initialInputMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
        initialOutputMint: exports.WRAPPED_SOL_MINT.toString(),
    }
});
