"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrentUserBadge = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const constants_1 = require("src/constants");
const accounts_1 = require("src/contexts/accounts");
const WalletPassthroughProvider_1 = require("src/contexts/WalletPassthroughProvider");
const utils_1 = require("../misc/utils");
const CurrentUserBadge = () => {
    var _a;
    const { publicKey, wallet } = (0, WalletPassthroughProvider_1.useWalletPassThrough)();
    const { accounts } = (0, accounts_1.useAccounts)();
    const solBalance = (0, react_1.useMemo)(() => {
        if (accounts[constants_1.WRAPPED_SOL_MINT.toString()]) {
            return accounts[constants_1.WRAPPED_SOL_MINT.toString()].balance;
        }
        return 0;
    }, [publicKey, accounts]);
    if (!wallet || !publicKey) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "flex items-center bg-[#191B1F] py-2 px-3 rounded-2xl h-7" }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: "w-4 h-4 rounded-full bg-[#191B1F] dark:bg-white-10 flex justify-center items-center", style: { position: 'relative' } }, { children: (0, jsx_runtime_1.jsx)("img", { alt: "Wallet logo", width: 16, height: 16, src: (_a = wallet === null || wallet === void 0 ? void 0 : wallet.adapter) === null || _a === void 0 ? void 0 : _a.icon }) })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "ml-2" }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "text-xs text-white" }, { children: (0, utils_1.shortenAddress)(`${publicKey}`) })) }))] })));
};
exports.CurrentUserBadge = CurrentUserBadge;
